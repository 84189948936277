<template>
  <el-container>
    <el-row style="width: 100%">
      <el-col :span="24">
        <div class="cardTitle">用户列表</div>
      </el-col>
      <el-col :span="24">
        <el-table :data="userList" style="width: 100%">
            <el-table-column prop="name" label="姓名" sortable></el-table-column>
            <el-table-column prop="gender" label="性别" sortable></el-table-column>
            <el-table-column prop="hemiplegicSide" label="偏瘫侧" sortable></el-table-column>
            <el-table-column prop="statusStr" label="状态" sortable></el-table-column>
            <el-table-column label="责任护士姓名" sortable>
              <template slot-scope="scope">
                <span>{{scope.row.nurse.name}}</span>
              </template>
            </el-table-column>
            <el-table-column label="责任护士编号" sortable>
              <template slot-scope="scope">
                <span>{{scope.row.nurse.jobNumber}}</span>
              </template>
            </el-table-column>
            <el-table-column fixed="right" label="操作">
                <template slot-scope="scope">
                    <el-button @click="getDetail(scope.row)" type="success" size="mini">详情</el-button>
                    <el-button @click="onDelete(scope.row)" type="danger" size="mini">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
      </el-col>
    </el-row>

    <!-- <el-dialog title="用户详情" width="30%" :visible.sync="userTypeVisible" >
        <el-form label-position="right" label-width="110px">
            <el-form-item label="姓名:">
                {{userDetailList.name}}
            </el-form-item>
            <el-form-item label="性别:">
                {{userDetailList.gender}}
            </el-form-item>
            <el-form-item label="身高:">
                {{userDetailList.height}}
            </el-form-item>
            <el-form-item label="体重:">
                {{userDetailList.weight}}
            </el-form-item>
            <el-form-item label="偏瘫侧:">
                {{userDetailList.hemiplegicSide}}
            </el-form-item>
            <el-form-item label="状态:">
                {{userDetailList.statusStr}}
            </el-form-item>
            <el-form-item label="住院号:">
                {{userDetailList.outpatientNumber}}
            </el-form-item>
            <el-form-item label="出生年月:">
                {{userDetailList.birthDay}}
            </el-form-item>
            <el-form-item label="联系方式:">
                {{userDetailList.phone}}
            </el-form-item>
            <el-form-item label="家属电话:">
                {{userDetailList.emergencyPhone}}
            </el-form-item>
            <el-form-item label="责任护士姓名:">
                {{userDetailList.nurse.name}}
            </el-form-item>
            <el-form-item label="责任护士编号:">
                {{userDetailList.nurse.jobNumber}}
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button type="primary" @click="userTypeVisible = false">确 定</el-button>
        </div>
    </el-dialog> -->
  </el-container>
</template>

<script>
export default {
  data() {
    return {
      userInfo: {},
      userList:[],
      userTypeVisible: false,
      userDetailList: []
    };
  },
  mounted() {
    let self = this;
    self.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    self.getUserList()
  },
  methods: {
    // 获取用户列表
    getUserList() {
      let self = this;
      self.$axios
        .get("/api/member/list", {
          headers: { token: self.userInfo.token }
        })
        .then(function (response) {
          self.userList = response.data.data;
        })
        .catch(function (error) {
          self.$message({
            message: "用户列表获取失败："+error.response.data.message,
            type: "warning",
          });
        });
    },
    // 删除
    onDelete(row) {
      let self = this;
      self
        .$confirm("确定删除 " + (row.name || "未命名") + "？")
        .then(() => {
          self.$axios
            .delete("/api/member/delete", {
              data: { guid: row.guid },
              headers: { token: self.userInfo.token },
            })
            .then(() => {
              self.$message({
                message: "删除成功",
                type: "success",
              });
              self.getUserList()
            })
            .catch((error) => {
              self.$message({
                message: error.response.data.message,
                type: "warning",
              });
            });
        })
        .catch(() => {});
    },
    // 获取用户详情
    getDetail(row) {
        // let self = this;
        // self.$axios
        // .get("/api/member/get/"+row.guid, {
        //   headers: { token: self.userInfo.token }
        // })
        // .then(function (response) {
        //   self.userDetailList = response.data.data;
        //   self.userTypeVisible = true
        // })
        // .catch(function (error) {
        //   self.$message({
        //     message: "用户详情获取失败："+error.response.data.message,
        //     type: "warning"
        //   })
        // })
        this.$router.push({ name: "user_userDetail", query: { guid: row.guid } });
    }
  }
}
</script>
